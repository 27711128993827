<template>
  <!--components/SearchRunbookTextbox.vue-->
  <div class="search_runbook_container">
    <div>
    <input ref="autosuggesionInput" 
      type="text" 
      placeholder="Search runbooks" 
      v-model="search_runbook_text_state" 
      @keyup="search_for_runbook_handler"
      :disabled="! page_mounted_state"
      />
        <i class="fal fa-search search_icon" aria-hidden="true"></i>
        <i class="fa-light fa-xmark" v-if="search_runbook_text_state.valueOf() != ''" @click="clear_search_field"></i>
    </div>
    <div ref="autosuggesionUI" v-if="showAutosuggesion && searchStore.searchSuggestion.filter(hide_xss_attempts).length > 0" class="search-autocomplete text-left">
      <ul class="suggestion-list">
        <li v-for="(suggestion, i) in searchStore.searchSuggestion.filter(hide_xss_attempts)" :key="i" class="d-flex justify-content-between">  
          <div @click="resultSelected(suggestion)" style="flex: 1;">{{ suggestion.title }}</div>
          <div class="d-flex pe-3" v-if="suggestion.from_community" @click="downloadCommunityTask(suggestion)">
            <div class="class_community_indicator">
              <!--
              <i class="fa-light fa-group-arrows-rotate"></i>
              -->
              community
            </div>
            <div  class="px-2" >
              <i class="fa-light fa-download"></i>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script setup>
import { downloadTask } from '~/services/taskService'
import { isEnabledShowSearchResultFromCommunity } from '~/services/settingService'
const search_runbook_text_state = useSearchRunbookTextState();
const searchStore = useSearchStore();
const settingStore = useSettingsStore();
let community_url = getCommunityURL();
const route = useRoute();   
let search_for_runbook_timer = 0;
const router = useRouter();
const query = computed(() => {
    let  query = ''
    if (route.query) {
        query = route.query
    }
    return query
})

let isEnabledShowCommunityResultFromDB = false;
if (typeof(window) != 'undefined') {
  isEnabledShowCommunityResultFromDB = await isEnabledShowSearchResultFromCommunity(settingStore);
}
const page_mounted_state = usePageMountedState();
const clear_search_field = () => {
  search_runbook_text_state.value = "";
}

const hide_xss_attempts = (task) => {
  if (! isJavascript(task)) {
    return true;
  } else {
    return false;
  }
}
const doSearchRaw = async () => {
  /*
  This doSearchRaw is the function that actually do the search.
  The doSearch function actually calls this doSearchRaw function.
  */
  let query_string = search_runbook_text_state.value;
  let tag = '';
  let userid = '';
  let collaborator = '';
  let url_prefix = community_url;
  let order_by = '';
  let with_pending_perms = false;
  let include_community = isEnabledShowCommunityResultFromDB;
  await searchStore.doSearch(query_string, tag, userid, collaborator, url_prefix, order_by, with_pending_perms, include_community);
}

const doSearch = async () => {
    if (search_for_runbook_timer != 0) {
      clearTimeout(search_for_runbook_timer);
    }

    if (search_runbook_text_state.value.length === 0) {
      showAutosuggesion.value = false
    } else {
      showAutosuggesion.value = true
    }

    if (search_runbook_text_state.value.length > 1) {
      searchStore.setSearchType("search_suggestion")
      search_for_runbook_timer = setTimeout(async function () {
        searchStore.setSearchPagination({ next_page_key: 0, has_more_results: true });
        searchStore.setCommunitySearchPagination({ next_page_key: 0, has_more_results: true });
        await searchStore.setSearchSuggestion([]);
        await doSearchRaw();
      }, 400);
    }
};

let previous_search_string = '';
const search_for_runbook_handler = async (event) => {
  if ((previous_search_string == search_runbook_text_state.value) && (event.key !== 'Enter')) {
      /*
      If a single key stroke didn't change the search string, we don't want to hit the server 
      and flicker the "result display" div
      */
      return;
  } else {
    previous_search_string = search_runbook_text_state.value;
  }
  /*
  if (['ArrowDown', 'ArrowUp', 'ArrowLeft', 'ArrowRight', 'CapsLock', 'Shift', 'Control', 'Alt', 'Meta'].includes(event.key)) {
      return;
  }
  */

  if (event.key !== 'Enter') {
    doSearch()
  } else {
    if (search_runbook_text_state.value.trim() == '') {
      return;
    } else {
      window.location.href = `?q=${encodeURIComponent(search_runbook_text_state.value)}&autocomplete=1`

      /*
      Use window.location.href for now because the below logic does not work somehow.  It displays empty screen until 
      the user hit the enter key in the URL bar.  I will need to come back to this later.
      
      searchStore.setSearchPagination({ next_page_key: 0, has_more_results: true });
      searchStore.setCommunitySearchPagination({ next_page_key: 0, has_more_results: true });
      await searchStore.setSearchSuggestion([]);

      await navigateTo(`/?q=${encodeURIComponent(search_runbook_text_state.value)}&autocomplete=1`);
      search_runbook_text_state.value = '';
      showAutosuggesion.value = false;
      */
    }    
  }
};

let autosuggesionUI = ref(null)
let autosuggesionInput = ref(null)

let showAutosuggesion = ref(false)
const resultSelected = async (item) => {
  showAutosuggesion.value = false;
  if ((item.hasOwnProperty('from_community')) && (item.from_community)) {
    let url = community_url.replace(/\/+$/,'') + '/tasks/' + item.id;
    window.open(url, '_blank');
  } else {
    await navigateTo("/tasks/" + item.id);
  }
};

const downloadCommunityTask = async (task) => {
  showAutosuggesion.value = false;
  await showWaitPage();
  let new_task = await downloadTask(task.id);
  hideWaitPage();
  dkAlert("Task downloaded.  You will now be redirected to the page for the downloaded task.", function() {
    dkNavigateTo(`/tasks/${new_task.id}`);
  })
  /*
  alert("Task downloaded.  You will now be redirected to the page for the downloaded task.");
  hideWaitPage();
  dkNavigateTo(`/tasks/${new_task.id}`);
  */
}


const handleClickOutside = (event) => {
  if (autosuggesionUI.value !== null) {
    if (!autosuggesionUI.value.contains(event.target) && !autosuggesionInput.value.contains(event.target)) {
      showAutosuggesion.value = false;
    }
  } else {
    // The click happens when autosuggesionUI is still being re-constructed, and so therefore, the click 
    // must have happened outside.  In other words, if the user clicks outside, before the search result 
    // comes back, do not show it.  If this is not desired, comment out the line below.
    showAutosuggesion.value = false;
  }
}

onMounted(() => {
  document.addEventListener('click', handleClickOutside)
})

onUnmounted(() => {
  document.removeEventListener('click', handleClickOutside)
})
const fetchMore = ref(false);

watch(fetchMore, async (newVal, oldVal) => {
  if(newVal === true){
    await doSearchRaw();
  }
})


watch(autosuggesionUI, async (newVal, oldVal) => {
  if ((typeof(newVal) != 'undefined') && (newVal != null)) {
    setTimeout(function(){
      let element = newVal.querySelector('.suggestion-list');
      useInfiniteScrollAutocomplete(fetchMore, searchStore, element);
    }, 200); // Wait for the .suggestion-list element to become available
  }
});

</script>

<style scoped>
.search-autocomplete{
  position: absolute;
  z-index: 99;
  top: 100%;
  left: 10px;
  width: 95%;
  border: 1px solid rgba(0,0,0,.15);
  border-radius: 3px;
  background-color: #ffffff;
  box-shadow: 0 5px 10px rgba(0,0,0,.2);
  cursor: pointer;
  text-align: left;
  border: 1 px solid;
}
.suggestion-list{
  margin-left: 0px;
  margin-bottom: 0px;
  padding-left: 0px;
  padding-bottom: 0px;
  max-height: 200px;
  overflow: auto;
}
.suggestion-list li{
  list-style: none;
  margin-top: 8px;
  padding-left: 20px;
}
.suggestion-list li:hover{
  background: #f3f3f3;
}
.search_runbook_container {
    flex: 1;
    margin-right: 43px;
    margin-left: 30px;
    position: relative;
}

.search_runbook_container .search_icon {
  position: absolute;
  padding-left: 5px;
  top: 15px;
  left: 16px;
  z-index: 1;
}
.search_runbook_container .fa-xmark {
    position: absolute;
    top: 15px;
    right: 5px;
    z-index: 1;
    color: red;
    cursor: pointer;
}

.search_runbook_container input {
  background-color: #f3f3f3;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 35px;
  padding-right: 30px;
  /* border: 1px solid #5A99F6; */
  border: none;
  border-radius: 5px;
  height: 35px;
  margin: 6px 10px;
  width: 100%;
}
.search_runbook_container input:focus {
  outline: none !important;
  border: 1px solid #5a99f6;
}
.class_community_indicator {
  font-size: 10px;
  border: 1px solid orange;
  border-radius: 5px;
  padding-left: 3px;
  padding-right: 3px;
  padding-top: 2px;
  background: #fffad7;
}
</style>
